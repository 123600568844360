input,
button,
textarea {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #aaaaaa;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #aaaaaa;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #aaaaaa;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #aaaaaa;
  }
  @include attention() {
    outline: none;
  }
}

.form-wrapper {
  max-width: 335px;
  margin: 30px auto 0;
  position: relative;
}

.form-title {
  color: var(--color-text-dark);
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 55px;
}

.form-subtitle {
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 25px;
}

.form-field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;

  .btn {
    width: 100%;
  }
}

.form-label {
  font-weight: 500;
  font-size: 10px;
  color: #aaaaaa;
  position: absolute;
  top: 30px;
  left: 25px;
  transition: all 0.2s var(--default-effect);
  transform: translate(0, -15px);
}

.form-input,
.form-textarea {
  background: #ffffff;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px 25px 15px;
  font-weight: 500;
  font-size: 15px;
  color: #111111;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.form-link {
  align-self: flex-start;
  margin-top: 30px;
  color: var(--color-primary);
}

.error-txt {
  width: 100%;
  color: #828282;
  font-size: 15px;
  padding: 10px 15px;
}

.is-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.btn {
  display: block;
  padding: 14px 12px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  font-size: 15px;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s var(--default-effect);
  border: none;
  min-width: 150px;

  &:not(:disabled):hover {
    outline: none;
    &.btn--primary {
      background: #141422;
      color: #fff;
    }

    &.btn--secondary {
      background: #fff;
      color: #141422;
    }

    &.btn--white {
      background: var(--color-primary);
    }

    &.btn--transparent {
      background-color: #fff;
    }
  }

  &.btn--primary {
    background: var(--color-primary);
    color: var(--color-text-dark);
  }

  &.btn--secondary {
    background: #141422;
    color: #fff;
  }

  &.btn--white {
    background: #fff;
  }

  &.btn--transparent {
    border: 1px solid var(--color-text-dark);
    min-width: 90px;
    padding: 5px 10px;
    font-size: 13px;
    background: transparent;
    box-shadow: none;

    &--invert {
      border-color: #fff;
      color: #fff;
    }
  }

  &.btn--blue {
    background: $color-button;
    color: #fff;
    box-shadow: none;

    &:hover {
      background: mix(black, $color-button, 20%);
    }
  }

  &:disabled {
    background: #e3e2e5;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
}

.btn-circle {
  appearance: none;
  border: none;
  background: #fff;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.form-notice {
  color: #828282;
  font-size: 13px;
  line-height: 2;
  font-weight: 500;
  padding: 20px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 50%;
    width: 50%;
    padding-top: 0;
  }
}

.checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 10px;
    font-weight: bold;
    background: #fff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    padding: 15px 45px 15px 15px;
    position: relative;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  // Box.
  & + label:before {
    content: '';
    width: 18px;
    height: 18px;
    background: url('../../images/icon-check.svg');
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 15px;
    transition: all 0.25s;
  }

  & ~ .form-textarea {
    display: none;
  }

  // Box hover
  &:hover + label:before {
  }

  // Box focus
  &:focus + label:before {
  }

  // Box checked
  &:checked + label:before {
    background: url('../../images/icon-checked.svg');
  }

  &:checked ~ .form-textarea {
    display: block;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
  }
}
