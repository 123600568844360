// DEFAULTS
// ==========================================================================
$font-default: 'Inter', sans-serif;
$font-title: $font-default;
$font-family-sans-serif: 'Inter', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-heading: 'Poppins', sans-serif;
$font-body: 'Lato', sans-serif;

$font-family-base: var(--bs-font-sans-serif) !default;
$font-family-code: var(--bs-font-monospace) !default;

// High-level base settings.

$base-font-family: $font-default !default;
$base-font-weight: normal !default;
$line-height-base: 1.25;

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$base-font-size: 16px !default;
$base-line-height: 24px !default;
$base-site-width: 1024px !default;

$base-header-utility-font-size: 12px !default;
$base-heading-font-family: $font-default !default;
$base-heading-font-weight: bold !default;
$base-heading-font-style: null !default;
$base-heading-text-transform: null !default;
$base-menu-primary-font-family: $font-default !default;
$base-menu-primary-font-weight: semibold !default;
$base-menu-primary-font-style: null !default;
$base-footer-nav-font-family: $font-default !default;
$base-footer-nav-font-style: null !default;

$small-font-size: 0.875em !default;
$sub-sup-font-size: 0.75em !default;

// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.

$base-spacing-unit: $base-line-height;
$base-spacing-unit--small: round($base-spacing-unit / 2);
$base-spacing-unit--large: round($base-spacing-unit * 2);

//Animation CSS duration
$default-duration: 0.5s;
$fast-duration: 0.25s;
$slow-duration: 1s;
$default-effect: ease-in-out;
$default-effect-ease: ease;

//
// Z-Layer Variables
//
// These key/value pairs will be used by mixins/_layering.scss.
//
// All z-index definitions will be stored in a Sass map
// and managed here to get a perfect stacking overview.
//

$z-layers: (
  'header': 999,
  'dropdown': 500,
  'modal': (
    'base': 100,
    'close': 101
  ),
  'default': 1,
  'below': -1
);

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin _assert-starts-at-zero($map, $map-name: '$grid-breakpoints') {
  @if length($map) > 0 {
    $values: map-values($map);
    $first-value: nth($values, 1);
    @if $first-value != 0 {
      @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
    }
  }
}
// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3
) !default;

$negative-spacers: if(
  $enable-negative-margins,
  negativify-map($spacers),
  null
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: 1rem !default;
$border-width: 1px !default;
$border-color: #cccccc !default;
$hr-margin-y: $spacer !default;
$hr-color: inherit !default;
$hr-height: $border-width !default;
$hr-opacity: 0.25 !default;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$paragraph-margin-bottom: 1rem !default;

$code-font-size: $small-font-size !default;
$code-color: $color-secondary !default;
$pre-color: null !default;

$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: transparent !default;

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($color-black, $table-striped-bg-factor) !default;

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($color-black, $table-active-bg-factor) !default;

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($color-black, $table-hover-bg-factor) !default;

$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-striped-order: odd !default;

$table-group-seperator-color: currentColor !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;

$table-variants: (
  'primary': color-level($color-primary, $table-bg-level),
  'secondary': color-level($color-secondary, $table-bg-level),
  // "success":    color-level($success, $table-bg-level),
  // "info":       color-level($info, $table-bg-level),
  // "warning":    color-level($warning, $table-bg-level),
  // "danger":     color-level($danger, $table-bg-level),
  // "light":      $light,
  // "dark":       $dark,
) !default;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;
